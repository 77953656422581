.main {
    $fs: 18;
    $fs-mb: 16;
    $fs-mb-lp: 22;

    width: 100%;
    font-size: vw($fs);
    padding-top: em(29, $fs);

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain($fs-mb, true);
    }

    &__wrap {
        display: flex;
        flex-direction: column;
    }

    &__block {
        &-wrap {
            display: flex;
            flex-direction: row;

            @media (--viewport-mobile-pt) {
                flex-direction: column;
                align-items: center;
            }
        }

        .main__content--img {
            position: relative;
        }

        .main__img {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
        }

        &--1,
        &--2,
        &--4,
        &--6 {
            .main__content {
                &:first-of-type {
                    width: 43.8%;

                    @media (--viewport-mobile-pt) {
                        width: 100%;
                    }
                }

                &:last-of-type {
                    width: 56.2%;

                    @media (--viewport-mobile-pt) {
                        width: 100%;
                    }
                }
            }
        }

        &--1 {
            .main__img {
                position: absolute;
                top: em(-50, $fs);
                left: 50%;
                transform: translateX(-50%);
                background-size: contain;
                width: em(800, $fs);
                height: em(541, $fs);
                background-image: url("/assets/images/main/laptop-2.png");

                @media (--viewport-mobile-pt) {
                    position: relative;
                    top: 0;
                    width: em(438, $fs-mb);
                    height: em(260, $fs-mb);
                }
            }
        }

        &--2 {
            margin-top: em(120, $fs);
            margin-bottom: em(-35.2, $fs);

            @media (--viewport-mobile-pt) {
                margin-top: em(-20, $fs-mb);
                margin-bottom: em(-15, $fs-mb);
            }

            .main__img {
                transform: translate(em(35, $fs), em(-35, $fs));
                background-size: contain;
                width: em(442, $fs);
                height: em(484, $fs);
                background-image: url("/assets/images/main/face.png");

                @media (--viewport-mobile-pt) {
                    transform: translate(0, 0);
                    width: em(194, $fs-mb);
                    height: em(243, $fs-mb);
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            @media (--viewport-mobile-pt) {
                .main__block-wrap {
                    flex-direction: column-reverse;
                }
            }
        }

        &--3,
        &--5 {
            .main__block-wrap {
                width: $width-content-desktop;
                border-radius: em(40, $fs);
                background: rgb(48 52 64 / 30%);
                text-align: center;

                @media (--viewport-mobile-pt) {
                    width: $width-content-mobile;
                }
            }
        }

        &--3 {
            padding-bottom: em(73, $fs);
            background-size: 100% 100%;
            background-position: top center;
            background-repeat: no-repeat;
            background-image: url("/assets/images/background/background-3.jpg");

            .main__block-wrap {
                padding: em(26, $fs) em(152, $fs) em(73, $fs);
                flex-direction: column;
                border: 1px solid $color-text-accent;
                position: relative;

                @media (--viewport-mobile-pt) {
                    padding: em(37, $fs-mb) em(14, $fs-mb) em(93, $fs-mb);
                    flex-direction: column-reverse;
                }
            }
        }

        &--4 {
            .main__content:first-of-type {
                width: 47%;

                @media (--viewport-mobile-pt) {
                    width: 100%;
                }
            }

            .main__img {
                transform: translateY(em(-6, $fs));
                margin-left: auto;
                margin-right: auto;
                width: em(464, $fs);
                height: em(238, $fs);
                background-size: contain;
                background-image: url("/assets/images/main/loupe.png");

                @media (--viewport-mobile-pt) {
                    width: 100%;
                }
            }
        }

        &--5 {
            .main__block-wrap {
                padding: em(30, $fs) em(86, $fs) em(38, $fs);

                @media (--viewport-mobile-pt) {
                    padding: em(30, $fs-mb) em(28, $fs-mb);
                    text-align: left;

                    .main__text {
                        line-height: 1.5;
                    }
                }
            }
        }

        &--6 {
            margin-top: em(99, $fs);

            @media (--viewport-mobile-pt) {
                margin-top: em(30, $fs-mb);
            }

            .main__img {
                transform: translate(0, em(-95, $fs));
                background-size: contain;
                width: em(443, $fs);
                height: em(363, $fs);
                background-image: url("/assets/images/main/lock.png");
            }

            .main__content--1 {
                background: radial-gradient(50% 50% at 50% 50%, #2a2e3a 0%, rgb(42 46 58 / 0%) 100%);

                @media (--viewport-mobile-pt) {
                    display: none;
                }
            }
        }

        &--7 {
            background-size: 100% 100%;
            background-position: top center;
            background-repeat: no-repeat;
            background-image: url("/assets/images/background/background-7.jpg");

            @media (--viewport-mobile-pt) {
                margin-top: em(50, $fs-mb);
            }

            .main__block-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
            }

            .main__footer {
                .developer {
                    position: absolute;
                    right: $padding-x-content-desktop;
                    bottom: em(25, $fs);

                    @media (--viewport-mobile), (--viewport-tablet-lp), (--viewport-desktop-s) {
                        right: 0;
                        left: 0;
                        text-align: center;
                    }

                    @media (--viewport-tablet-pt) {
                        bottom: 0;
                    }
                }

                .advertiser {
                    max-width: em(700, 12);
                    width: 100%;
                    margin-bottom: em(25, 12);

                    @media (--viewport-mobile), (--viewport-tablet-lp), (--viewport-desktop-s) {
                        margin-bottom: em(80, 12);
                    }
                }
            }
        }
    }

    &__content {
        width: 100%;
    }

    &__title {
        font-size: em(32, $fs);
        font-weight: $font-weight-regular;
        line-height: em(40, 32);
        color: $color-text-accent;
        margin-bottom: em(25, $fs);

        @media (--viewport-mobile-pt) {
            font-size: em(20, $fs-mb);
        }
    }

    &__text {
        &:not(:last-of-type) {
            margin-bottom: em(25, $fs);
        }
    }

    &__common {
        margin-bottom: em(68, $fs);
        color: $color-text-light;
        line-height: 1.4;

        @media (--viewport-mobile-pt) {
            margin-top: em(46, $fs-mb);
            margin-bottom: 0;
            font-weight: 200;
        }
    }

    &__charts {
        display: flex;
        align-items: flex-start;
        column-gap: em(86, $fs);

        @media (--viewport-mobile-pt) {
            flex-direction: column;
            gap: em(55, $fs-mb);
        }
    }

    &__chart {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        row-gap: em(32, $fs);

        &-value {
            padding-top: em(250 / 3, 64);
            width: em(250, 64);
            height: em(250, 64);
            font-size: em(64, $fs);
            font-weight: $font-weight-regular;
            background: var(--grad-1, linear-gradient(180deg, #ff6b6b 0%, #ff5959 50%, #ff4646 100%));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-size: contain;
                background-position: bottom center;
                background-repeat: no-repeat;
            }
        }

        &--1 {
            .main__chart-value {
                &::before {
                    background-image: url("/assets/images/main/pie-1.png");
                }
            }
        }

        &--2 {
            .main__chart-value {
                &::before {
                    background-image: url("/assets/images/main/pie-2.png");
                }
            }
        }

        &-text {
            font-weight: $font-weight-medium;

            @media (--viewport-mobile-pt) {
                padding-right: em(17, $fs-mb);
                padding-left: em(17, $fs-mb);
            }
        }
    }

    &__period {
        position: absolute;
        bottom: em(22, $fs);
        right: em(152, $fs);
        color: $color-text-light;

        @media (--viewport-mobile-pt) {
            font-size: em(18, $fs-mb);
            bottom: em(26, $fs-mb);
            right: auto;
        }
    }

    &__cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 100%);
        grid-template-rows: 1fr;
        border-radius: em(20, $fs);
        gap: 0;

        @media (--viewport-mobile-pt) {
            width: 100%;
            grid-template-columns: repeat(7, 100%);
            grid-template-rows: 1fr;
            gap: 0;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: rgb(42 46 58 / 30%);
        padding: em(33, $fs) em(96, $fs) em(28, $fs);
        font-weight: $font-weight-medium;
        text-align: center;
        row-gap: em(30, $fs);
        height: 100%;

        @media (--viewport-mobile-pt) {
            padding-left: em(33, $fs-mb);
            padding-right: em(33, $fs-mb);
            border-radius: 0;
        }

        &:first-of-type {
            border-radius: em(20, $fs) 0 0 em(20, $fs);
        }

        &:last-of-type {
            border-radius: 0  em(20, $fs) em(20, $fs) 0;
        }

        &--1 { grid-area: 1 / 1 / 2 / 2; }
        &--2 { grid-area: 1 / 2 / 2 / 3; }
        &--3 { grid-area: 1 / 3 / 2 / 4; }
        &--4 { grid-area: 1 / 4 / 2 / 5; }
        &--5 { grid-area: 1 / 5 / 2 / 6; }
        &--6 { grid-area: 1 / 6 / 2 / 7; }

        @media (--viewport-mobile-pt) {
            font-size: em(18, $fs-mb);
            row-gap: em(10, $fs-mb);
            width: 100%;

            &--1 {
                grid-area: 1 / 1 / 2 / 2;

                .main__card-text {
                    font-size: vw(15, true);
                }
            }
            &--2 { grid-area: 1 / 2 / 2 / 3; }
            &--3 { grid-area: 1 / 3 / 2 / 4; }
            &--4 { grid-area: 1 / 4 / 2 / 5; }
            &--5 { grid-area: 1 / 5 / 2 / 6; }

            &--6 {
                grid-area: 1 / 6 / 2 / 7;

                .main__card-icon {
                    display: none;
                }

                .main__card-text {
                    font-size: vw(15, true);
                }
            }
        }

        &-icon {
            background-size: contain;
            background-position: top center;
            background-repeat: no-repeat;
            margin: 0 auto em(45, $fs);
        }

        &--glasses {
            .main__card-icon {
                width: em(65, $fs);
                height: em(54, $fs);
                background-image: url("/assets/images/cards-el/glasses.png");
            }
        }

        &--security {
            .main__card-icon {
                width: em(67, $fs);
                height: em(52, $fs);
                background-image: url("/assets/images/cards-el/security.png");
            }
        }

        &--password {
            .main__card-icon {
                width: em(58, $fs);
                height: em(63, $fs);
                background-image: url("/assets/images/cards-el/password.png");
            }
        }

        &--eye {
            .main__card-icon {
                width: em(65, $fs);
                height: em(45, $fs);
                background-image: url("/assets/images/cards-el/eye.png");
            }
        }

        &--fingerprint {
            .main__card-icon {
                width: em(65, $fs);
                height: em(60, $fs);
                background-image: url("/assets/images/cards-el/fingerprint.png");
            }
        }

        &--biometrics {
            .main__card-icon {
                width: em(58, $fs);
                height: em(63, $fs);
                background-image: url("/assets/images/cards-el/biometrics.png");
            }
        }

        &--alert {
            .main__card-icon {
                width: em(58, $fs);
                height: em(52, $fs);
                background-image: url("/assets/images/cards-el/alert.png");
                margin-bottom: em(16, $fs);
            }
        }
    }

    .swiper-container {
        width: em(600, $fs);
        margin-top: em(44, $fs);
        margin-bottom: em(156, $fs);
        height: fit-content;
        border-radius: em(20, $fs);
        overflow: hidden;
        position: relative;

        @media (--viewport-mobile-pt) {
            width: 100%;
            margin-bottom: em(60, $fs-mb);
        }

        .arrow-swiper {
            display: block;
            width: em(70, $fs);
            height: em(70, $fs);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1000;

            @media (--viewport-mobile-pt) {
                width: em(41, $fs-mb);
                height: em(41, $fs-mb);
            }

            &:active {
                @media (--viewport-mobile-pt) {
                    transform-origin: center em(60, $fs-mb);
                }

                .arrow-swiper__prev,
                .arrow-swiper__next {
                    transform: scale(1.5);
                }
            }

            .arrow-swiper__prev,
            .arrow-swiper__next {
                transition: transform 0.2s ease;
            }

            &.slider__prev {
                left: 0;
            }

            &.slider__next {
                right: 0;
            }
        }
    }
}

// Derived from poly-fluid-sizing v1.2.1
// https://github.com/Jakobud/poly-fluid-sizing
@use "sass:list";
@use "sass:map";

// Dependency functions
@import "node_modules/poly-fluid-sizing/linear-interpolation.scss";

@mixin pfs-iteration($property, $map, $keys, $j) {
    $value1: map.get($map, list.nth($keys, $j));
    $value2: map.get($map, list.nth($keys, ($j + 1)));

    // If values are not equal, perform linear interpolation
    @if $value1 != $value2 {
        #{$property}: linear-interpolation((list.nth($keys, $j): $value1, list.nth($keys, ($j + 1)): $value2));
    } @else {
        #{$property}: $value1;
    }
}

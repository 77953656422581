@use "sass:list";
@use "sass:map";

@mixin pfs-start($property, $map, $keys, $fixFirst: true, $fixLast: true) {
    // Minimum size
    @if $fixFirst {
        #{$property}: map.get($map, list.nth($keys, 1));
    } @else {
        // Interpolated size through the first two breakpoints
        $value1: map.get($map, list.nth($keys, 1));
        $value2: map.get($map, list.nth($keys, 2));

        // If values are not equal, perform linear interpolation
        @if $value1 != $value2 {
            #{$property}: linear-interpolation((list.nth($keys, 1): $value1, list.nth($keys, 2): $value2));
        } @else {
            #{$property}: $value1;
        }
    }
}

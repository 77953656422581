.header {
    $fs: 32;
    $fs-mb: 20;

    font-size: vw($fs);
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/background/background-1.jpg");
    position: relative;

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain($fs-mb, true);
        background-image: url("/assets/images/background/background-1-mb.png");
    }

    &,
    &__decor {
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
    }

    &__decor {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-84%, -50%);
        width: em(804, $fs);
        height: em(804, $fs);
        background-image: url("/assets/images/background/hacker.png");

        @media (--viewport-mobile-pt) {
            left: 50%;
            transform: translate(-50%, 0);
            top: auto;
            bottom: em(-10, $fs-mb);
            width: em(388, $fs-mb);
            height: em(388, $fs-mb);
        }

        @media (--viewport-mobile-lp) {
            transform: translate(-90%, 0);
            top: 0;
            left: 50%;
            width: vh(700, true);
            height: vh(600, true);
        }
    }

    &__footnotes {
        display: flex;
        justify-content: space-between;
        column-gap: em(20, $fs);
        width: em(850, $fs);
        padding-top: em(24, $fs);
        margin: 0 auto;
        position: relative;
        z-index: 3;

        .footnote {
            font-size: em(18, $fs);
            color: $color-text-light;
            text-decoration: none;
            transition: color 0.3s ease;

            &:hover {
                color: $color-text;
            }
        }

        @media (--viewport-mobile-pt) {
            display: none;
        }
    }

    &__title {
        transform: translate(88%, -50%);
        position: absolute;
        top: 50%;
        right: 50%;
        max-width: em(690, $fs);
        width: 100%;

        @media (--viewport-mobile-pt) {
            padding-top: em(63, $fs-mb);
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            position: static;
            transform: none;
        }

        @media (--viewport-mobile-lp) {
            transform: translate(-10%, -46%);
            right: 0;
            max-width: em(350, $fs-mb);
        }

        .h1 {
            font-size: em(70, $fs);
            line-height: em(90, 70);
            font-weight: $font-weight-ExtraBold;

            @media (--viewport-mobile-pt) {
                font-size: em(40, $fs-mb);
            }

            @media (--viewport-mobile-lp) {
                font-size: vh(50, true);
            }
        }

        .subtitle {
            margin-top: em(37, 32);
            margin-left: vw(193);
            font-size: em(32, $fs);
            line-height: em(41, 32);
            font-weight: $font-weight-regular;

            @media (--viewport-mobile-pt) {
                margin-top: em(32, $fs-mb);
                margin-left: auto;
                color: $color-text-light;
                padding-left: em(36, $fs-mb);
                padding-right: em(36, $fs-mb);
            }

            @media (--viewport-mobile-lp) {
                font-size: vh(35, true);
                margin-left: 0;
            }
        }

        .main-button {
            margin-top: em(63, 20);
            margin-left: vw(193);
            font-size: em(20, $fs);

            @media (--viewport-mobile-pt) {
                display: none;
            }

            @media (--viewport-mobile-lp) {
                font-size: vh(18, true);
                margin-left: 0;
            }
        }
    }
}

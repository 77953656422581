@use "sass:math";

// colors
$color-bg: #1b1b1d;

// $color-bg-accent: #b835a0;
$color-text: #fef5f5;
$color-text-accent: #ff6b6b;
$color-text-light: #d4cad2;
$color-about: #574f55;

// text
$font-default: "Manrope", system-ui, sans-serif;

// Basing Weights
$font-weight-default: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-ExtraBold: 800;
$basePxDesktop: 18;
$baseWidthDesktop: 1440;
$baseHeightDesktop: 1080;
$maxWidthDesktop: 1440;

// add
$basePxMb: 16;
$baseWidthMb: 375;
$baseHeightMb: 667;

// Font sizes
// to calc 1rem with poly-fluid-sizing
$map-font-size-lp: (
    560px: 18px,
    850px: 18px,
    852px: 18px,
    1440px: 18px
);
$map-font-size-pt: (
    320px: 14px,
    375px: 16px,
    425px: 17px,
    1024px: 18px
);

// Add one more variable if mobile line-height differs from desktop one
$line-height-default: math.div(24, $basePxDesktop);

// Line height of accent font family text
$line-height-accent: math.div(126, 140);
$width-content-desktop: vw(1140);
$padding-x-content-desktop: vw(150);
$width-content-mobile: vw-vh-contain(315, true);
$padding-x-content-mobile: vw-vh-contain(40, true);
$w-content-padding-x: calc($width-content-desktop + $padding-x-content-desktop * 2);
$p-x-content-mobile: calc($width-content-mobile + $padding-x-content-mobile * 2);

.main-button {
    $fs: 20;
    $fs-mb: 18;
    $delay: 0.3s;

    &,
    &-outbound {
        display: inline-block;
        font-weight: $font-weight-bold;
        text-decoration: none;
        text-align: center;
        transition: color $delay ease, box-shadow $delay ease;
    }

    font-size: em($fs, 18);
    min-width: em(243, $fs);
    padding: em(18, $fs) em(45, $fs);
    border-radius: em(20, $fs);
    line-height: 163.418%;
    border: solid $color-text;

    &:hover {
        color: $color-text-accent;
        box-shadow: 0 4px 20px 0 rgb(255 107 107 / 21%), 0 -4px 20px 0 rgb(255 107 107 / 21%), 0 2px 10px 0 rgb(255 107 107 / 21%), 0 2px 10px 0 rgb(255 107 107 / 21%);
    }

    &-outbound {
        font-size: em(18, 18);
        min-width: em(191, $fs);
        padding: em(18, $fs) em(30, $fs);
        border-radius: em(30, 18);
        border: em(1, $fs) solid $color-text-accent;

        @media (--viewport-mobile-pt) {
            font-size: vw($fs-mb, true);
            border-radius: em(20, $fs-mb);
            padding: em(16, $fs-mb) em(25, $fs-mb);
        }

        &:hover {
            color: $color-text-accent;
            box-shadow: 0 4px 20px 0 rgb(255 107 107 / 21%), 0 -4px 20px 0 rgb(255 107 107 / 21%), 0 2px 10px 0 rgb(255 107 107 / 21%), 0 2px 10px 0 rgb(255 107 107 / 21%);
        }
    }
}

@font-face {
    font-family: Manrope;
    src:
        url("assets/fonts/subset-Manrope-ExtraLight.woff2") format("woff2"),
        url("assets/fonts/subset-Manrope-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Manrope;
    src:
        url("assets/fonts/subset-Manrope-Light.woff2") format("woff2"),
        url("assets/fonts/subset-Manrope-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Manrope;
    src:
        url("assets/fonts/subset-Manrope-Regular.woff2") format("woff2"),
        url("assets/fonts/subset-Manrope-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Manrope;
    src:
        url("assets/fonts/subset-Manrope-Medium.woff2") format("woff2"),
        url("assets/fonts/subset-Manrope-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Manrope;
    src:
        url("assets/fonts/subset-Manrope-Bold.woff2") format("woff2"),
        url("assets/fonts/subset-Manrope-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Manrope;
    src:
        url("assets/fonts/subset-Manrope-ExtraBold.woff2") format("woff2"),
        url("assets/fonts/subset-Manrope-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@import "base/mixins/reduced-motion.scss";
@import "base/mixins/reset.scss";
@import "mixins/font-size.scss";

/* main styles */
html,
body {
    text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    font-family: $font-default;
    font-size: rem(18);
    font-weight: $font-weight-default;
    color: $color-text;
    background: $color-bg;
    width: 100%;
    overflow-x: hidden;
    height: 100vh;
    scroll-behavior: smooth;

    @include font-size;
    @include reduced-motion;
}

@include reset;

// Remove if total buttons reset is not needed
a,
button {
    @include reset-btn;
}

b,
strong {
    /* Customizing font-weight according to design */

    /* e.g. Semi Bold */
    font-weight: $font-weight-medium;
}

.container {
    width: $w-content-padding-x; // 1440 - 150 - 150 = 1140
    padding-left: $padding-x-content-desktop;
    padding-right: $padding-x-content-desktop;
    margin-left: auto;
    margin-right: auto;

    @media (--viewport-mobile-pt) {
        padding-left: $padding-x-content-mobile;
        padding-right: $padding-x-content-mobile;
    }
}

.red-text {
    color: $color-text-accent;
}

.big-text {
    font-size: vw(22);

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain(16, true);
    }
}

.minwidth-text {
    padding-left: vw(74);
    padding-right: vw(74);

    @media (--viewport-mobile-pt) {
        padding-left: 0;
        padding-right: 0;
    }
}

.developer {
    color: $color-about;
    font-size: vw(12);
}

.advertiser {
    font-size: vw(12);
    font-weight: $font-weight-regular;
    text-align: center;
    color: $color-text-light;
}
